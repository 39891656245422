import {Wrapper, Logo, Nav,Button} from "../../components";
import {useState} from "react";
function Navbar () {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
        <div className="h-[50px] bg-dark-background  justify-between items-center px-2 hidden md:flex">
          <div className="flex items-center ">
              <Logo/>
              <Nav/>
          </div>
            <div className="flex gap-x-2">
                <Button onClick={() => window.location.href = `https://exchange.gmskripto.com/signin`} variant="secondary">Giriş Yap</Button>
                <Button onClick={() => window.location.href = `https://exchange.gmskripto.com/signup`}>Kayıt Ol</Button>
            </div>
        </div>
            <div className="block md:hidden">
                <nav className="bg-dark-background p-4">
                    <div className="flex items-center justify-between flex-wrap">
                        <Logo/>
                        <div className="block lg:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="text-white focus:outline-none"
                            >
                                <svg
                                    className="fill-current h-6 w-6"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    {isOpen ? (
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M19.293 4.707a1 1 0 010 1.414L13.414 12l5.879 5.879a1 1 0 01-1.414 1.414L12 13.414l-5.879 5.879a1 1 0 01-1.414-1.414L10.586 12 4.707 6.121a1 1 0 011.414-1.414L12 10.586l5.879-5.879a1 1 0 011.414 0z"
                                        />
                                    ) : (
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4 5h16a1 1 0 010 2H4a1 1 0 010-2zm0 6h16a1 1 0 010 2H4a1 1 0 010-2zm0 6h16a1 1 0 010 2H4a1 1 0 010-2z"
                                        />
                                    )}
                                </svg>
                            </button>
                        </div>
                        <div
                            className={`${
                                isOpen ? 'block' : 'hidden'
                            } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
                        >
                            <div className="text-sm lg:flex-grow">
                                <Nav/>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export {Navbar}

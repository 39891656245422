import {Outlet} from "react-router-dom";
import {Navbar, Footer} from "../containers";

const MainLayout = () => {
    return (
        <div>
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    )
}

export default MainLayout;

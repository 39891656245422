import {createBrowserRouter} from "react-router-dom";

import MainLayout from "../layout/MainLayout";
import {MainScreen,PrivacyPolicy} from "../screens";


const routes = createBrowserRouter([
    {
        element:<MainLayout/>,
        children:[
            {
                path:"/",
                element:<MainScreen/>
            },
            {
                path:"privacy-policy",
                element:<PrivacyPolicy/>
            }
        ]
    }
]);

export default routes;
